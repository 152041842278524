import dynamic from "next/dynamic";
import { Section } from "..";
import {
  ListTitle,
  GridWrapper,
  ListItemsWrapper,
  RightSide,
  Image,
  Claim,
} from "./styles";
import { renderLineBreaks } from "../../../utils";

const DropDownListItem = dynamic(() =>
  import("../../elements/drop-down-list-item/drop-down-list-item")
);

export const DropDownList = ({
  sectionTitle,
  sectionSubtitle,
  hideLineSeparation,
  listTitle,
  listType,
  listItems,
  image,
  claim,
  displaySetting,
  reversePosition,
  showImageMobile
}) => {
  const hasRightSide = image || claim;
  const hasListItems = listItems.length > 0;

  return (
    <Section
      title={sectionTitle}
      subtitle={sectionSubtitle}
      hideLine={hideLineSeparation}
      displayMode={displaySetting?.displayMode}
      smallMargin={displaySetting?.smallBottomMargin}
    >
      {listTitle && <ListTitle>{listTitle}</ListTitle>}
      <GridWrapper>
        {hasListItems && (
          <ListItemsWrapper $hasRightSide={hasRightSide}>
            {listItems.map((item, id) => (
              <DropDownListItem
                key={`drop-down-list-item-${item.id}`}
                index={id + 1}
                type={listType}
                {...item}
              />
            ))}
          </ListItemsWrapper>
        )}
        {hasRightSide && (
          <RightSide $reversePosition={reversePosition} $showImageMobile={showImageMobile}>
            {image && <Image media={image} />}
            {claim && <Claim>{renderLineBreaks(claim)}</Claim>}
          </RightSide>
        )}
      </GridWrapper>
    </Section>
  );
};

export default DropDownList;
