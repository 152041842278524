import tw from "tailwind-styled-components";
import { NextImage } from "../../elements";

export const ListTitle = tw.div`
  text-xl desktop:text-2xl
  font-semibold
  mb-6
`;

export const GridWrapper = tw.div`
  grid
  grid-cols-5
  gap-4
`;

export const ListItemsWrapper = tw.div`
  col-span-5
  flex
  flex-col
  gap-4

  ${({ $hasRightSide }) => $hasRightSide && "desktop:col-span-3"}
`;

export const RightSide = tw.div`
  desktop:flex
  ${({ $showImageMobile }) => !$showImageMobile && "hidden"}
  desktop:col-span-2
  col-span-5	
  flex-col
  gap-4
  ${({ $reversePosition }) => $reversePosition &&
  ` desktop:col-start-1
    desktop:col-end-3
    desktop:row-start-1
    [&>img]:mb-4
  `}
`;

export const Image = tw(NextImage)`
  rounded-[32px]
  h-80
  max-h-80
  object-cover
`;

export const Claim = tw.div`
  px-16
  py-11
  text-4xl
  text-center
  bg-secondary-100
  rounded-[32px]
`;
